a,
abbr,
acronym,
address,
applet,
article,
aside,
audio,
b,
big,
blockquote,
body,
canvas,
caption,
center,
cite,
code,
dd,
del,
details,
dfn,
div,
dl,
dt,
em,
embed,
fieldset,
figcaption,
figure,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
hgroup,
html,
i,
iframe,
img,
ins,
kbd,
label,
legend,
li,
main,
mark,
menu,
nav,
object,
ol,
output,
p,
pre,
q,
ruby,
s,
samp,
section,
small,
span,
strike,
strong,
sub,
summary,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
time,
tr,
tt,
u,
ul,
var,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section {
  display: block
}

body {
  line-height: 1
}

ol,
ul {
  list-style: none
}

blockquote,
q {
  quotes: none
}

blockquote:after,
blockquote:before,
q:after,
q:before {
  content: '';
  content: none
}

table {
  border-collapse: collapse;
  border-spacing: 0
}

.clearfix:after {
  display: block;
  clear: both;
  visibility: hidden;
  height: 0;
  overflow: hidden;
  content: "."
}

.fl {
  float: left
}

.fr {
  float: right
}

a {
  text-decoration: none
}
.home-wrapper{
  /* background-color: #181818; */
}
.App .home-box{
  padding-top: 0px;
}
.login_out {
  width: 330px !important;
}
.login_out .ant-modal-body {
  padding: 20px !important
}

.order_image{
  width: 50px;
  height:50px;
  background:url('./images/lottery/icon.png') no-repeat;
  background-position:-41px -29px;
  margin: 0 auto;
  margin-top: 40px
}
.order_image_lottery {
  width: 41px;
  height: 46px;
  background:url('./images/lottery/icon-lottery.png') center no-repeat;
  margin-bottom: 10px
}